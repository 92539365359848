import { useEffect, useState } from "react";
import { getFolhaCertaPermissions, UsuarioFolhaCerta } from "../api";

export const useFolhaCertaUser = ({
  enableRequest,
  selectedCompanyId,
}: {
  enableRequest: boolean;
  selectedCompanyId: string;
}) => {
  const [folhaCertaUser, setFolhaCertaUser] =
    useState<UsuarioFolhaCerta | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getFolhaCertaPermissions({
        enableRequest,
        selectedCompanyId,
      });
      setFolhaCertaUser(result);
    };

    fetchData();
  }, [enableRequest, selectedCompanyId]);

  return {
    folhaCertaUser,
  };
};
