export default {
  translations: {
    menu: {
      admin: {
        home: "Início",
        users: "Equipe",
        cards: "Cartões físicos",
        marketplace: "Parceiros e descontos",
        documentManagement: "Gestão de documentos",
        settings: "Configurações",
        benefits: {
          title: "Benefícios",
          companyBenefits: {
            title: "Benefícios da empresa",
            overView: "Prateleira de benefícios",
            setup: "Atribuição de benefícios",
            orders: "Pedido de benefícios",
            placedOrders: "Pedido de benefícios",
            deposits: "Depósitos",
          },
          finance: {
            title: "Financeiro",
            billets: "Boletos",
            cash: "Flash Cash",
          },
          payrollLoan: "Consignado",
          points: {
            title: "Pontos",
            customBenefits: "Benefícios customizados",
            policies: "Políticas",
            policiesAssignment: "Atribuição de políticas",
          },
          reports: "Relatórios de dados",
        },
        expense: {
          title: "Despesas",
          approvals: "Aprovações",
          corporateCard: {
            title: "Contas corporativas",
            balance: "Saldo corporativo",
            cards: "Cartões corporativos",
            accounts: "Saldos e cartões",
          },
          dashboard: "Visão geral",
          externalCards: "Cartões de terceiros",
          suppliers: "Fornecedores",
          accounting: {
            title: "Contabilizações",
            companyPayables: "Despesas com fornecedor",
            accountability: "Prestação de contas",
            advances: "Adiantamentos",
          },
          customizations: "Preferências",
          analytics: "Relatórios de dados",
        },
        people: {
          title: "Pessoas",
          employmentLifecycle: {
            title: "Fluxos de pessoas",
            hiring: "Admissão",
            termination: "Desligamento",
            recognition: "Reconhecimento",
          },
          working: {
            title: "Controle de jornada",
            activities: "Atividades",
            approvals: "Aprovações",
            timeSheet: "Espelhos da equipe",
            shiftSchedule: "Escalas de trabalho",
            absences: "Eventos da equipe",
            vacations: "Férias da equipe",
            timeBank: "Banco de horas",
            dataExport: "Exportação de dados",
            integrations: "Integrações",
            workScheduleRules: "Regras de jornada",
            manageWorkshifts: "Escalas de equipe",
            importHistory: "Histórico de importações",
          },
          performance: {
            title: "Performance",
            evaluations: "Avaliações",
            feedbacks: "Feedbacks",
            criterials: "Critérios avaliativos",
            cycles: "Ciclos",
            idp: "PDIs",
          },
          training: "Treinamentos",
          engagement: {
            title: "Engajamento",
            communications: "Comunicações",
            surveys: "Pesquisas",
          },
          orgchart: "Organograma",
          insights: "Relatórios de dados",
          companySettings: "Configurações da empresa",
          recruitment: {
            title: "Recrutamento",
            jobOpenings: "Gestão de vagas",
          },
        },
      },
      employee: {
        home: "Início",
        marketplace: "Parceiros e descontos",
        cards: "Cartões",
        wallet: "Carteira",
        statement: "Extrato",
        corporateExpenses: {
          title: "Despesas corporativas",
          accountability: "Prestação de contas",
          advance: "Adiantamentos",
          analytics: "Relatórios de dados",
        },
        corporateTravels: {
          title: "Viagens corporativas",
          search: "Pesquisa de viagem",
          requests: "Solicitações realizadas",
          approvals: "Aprovações",
          monitoring: "Monitoramento",
          reports: "Relatórios",
        },
        timeAndAttendance: {
          title: "Controle de jornada",
          timeClock: "Marcação de ponto",
          timeSheet: "Espelho de ponto",
          workshift: "Escala de trabalho",
          vacation: "Férias",
        },
        performance: {
          title: "Performance",
          feedbacks: "Feedbacks",
          cycles: "Ciclos",
          idp: "PDIs",
        },
        training: {
          title: "Treinamentos",
        },
        communication: {
          title: "Comunicações",
          companyMessages: "Mensagens da empresa",
          companySurveys: "Pesquisas da empresa",
        },
        orgChart: {
          title: "Organograma",
        },
        profileSettings: { title: "Configurações do perfil" },
        recruitment: {
          title: "Recrutamento",
          jobOpenings: "Gestão de vagas",
        },
      },
      benefits: {
        title: "Gestão de Benefícios",
        activeCards: "Seus Cartões",
        api: "Acesso Programático",
        deposits: "Depósitos",
        employees: "Pedido de Beneficios",
        payrollLoan: "Consignado",
        points: {
          title: "Pontos",
          customBenefits: "Benefícios Customizados",
          policies: "Políticas",
          policiesAssignment: "Atribuição de Políticas",
        },
        yourBenefits: "Seus Benefícios",
        simpleAssignment: "Atribuição de benefícios",
        placedOrders: "Pedido de Benefícios",
      },
      cards: "Cartões",
      employees: "Equipe",
      expenses: {
        title: "Gestão de Despesas",
        corporateCard: {
          title: "Cartão Corporativo",
          home: "Gerenciar Cartões",
          management: "Gerenciar Saldo",
        },
        expenses: "Despesas",
        advances: "Adiantamentos",
        approvals: "Aprovações",
        accounting: {
          title: "Contabilizações",
          accountability: "Prestação de contas",
          payables: "Despesa com fornecedor",
          advances: "Adiantamento",
        },
        customizations: "Personalizações",
        analytics: "Insights e reports",
        vendors: "Fornecedores",
        travel: {
          title: "Viagens",
          list: "Requisições",
          search: "Cotação",
          approvals: "Aprovações",
          monitoring: "Monitoramento",
          reports: "Relatórios",
        },
      },
      finance: {
        title: "Financeiro",
        payments: "Boletos",
        balance: "Flash Cash",
      },
      home: "Início",
      myFlash: {
        title: "Meu Flash",
        cards: "Meus Cartões",
        wallet: "Minha Carteira",
        statement: "Meu Extrato",
      },
      marketplace: "Parceiros e descontos",
      people: {
        title: "Gestão de Pessoas",
        manageFlows: {
          flows: "Gestão de fluxos",
          hiring: "Admissão",
          resignation: "Desligamento",
          recognition: "Reconhecimento",
        },
        lms: {
          courses: "Treinamentos",
          myCourses: "Meus Treinamentos",
          manageCourses: "Gerenciar Treinamentos",
        },
        engagement: {
          engagement: "Engajamento",
          manageCommunications: "Gerenciar Comunicações",
          manageSurveys: "Gerenciar Pesquisas",
          mySurveys: "Minhas Pesquisas",
        },
        orgchart: "Organograma",
        analytics: "Insights",
        performance: {
          performance: "Performance",
          manageCriterials: "Gerenciar Critérios avaliativos",
          manageCycles: "Gerenciar Ciclos",
          myCycles: "Ciclos",
          manageEvaluations: "Gerenciar Avaliações",
          manageFeedbacks: "Gerenciar Feedbacks",
          myFeedbacks: "Meus Feedbacks",
          manageIdp: "Gerenciar PDIs",
          myIdp: "Meu PDI",
        },
        timeAndAttendance: {
          timeAndAttendance: "Controle de Jornada",
          clockin: "Marcar ponto",
          timeSheet: "Meu espelho",
          myVacations: "Minhas férias",
          activities: "Atividades",
          approvals: "Aprovações",
          timeSheets: "Gerenciar espelhos",
          events: "Gerenciar eventos",
          vacations: "Gerenciar férias",
          bankHours: "Banco de horas",
          exportData: "Exportação de dados",
          preferences: "Preferências de jornada",
          workshift: "Minha escala",
          integrations: "Integrações",
          manageWorkshifts: "Gerenciar escalas",
          importHistory: "Histórico de importações",
        },
      },
      reports: "Relatórios",
      settings: "Configurações",
      documentManagement: {
        title: "Gestão de documentos",
      },
    },
    menuItem: {
      newItem: "Novo",
    },
  },
};
