import { UsuarioFolhaCerta } from "../../api";

const getTimeAndAttendanceSubMenus = (folhaCertaUser: UsuarioFolhaCerta) => {
  const MarcarPonto = folhaCertaUser?.Configuracao.MarcarPonto;
  const ExibirMarcarPontoWeb =
    folhaCertaUser?.Configuracao.ExibirMarcarPontoWeb;
  const ExibirFerias = folhaCertaUser?.Configuracao.ExibirFerias;

  const isGestor = folhaCertaUser?.IsGestor;

  const isAdmin = folhaCertaUser?.IsAdmin;

  const isAdminOrGestor = isGestor || isAdmin;

  return [
    {
      name: "people.timeAndAttendance.clockin",
      route: "time-and-attendance/clockin",
      isFolhaCertaAllowed: ExibirMarcarPontoWeb,
    },
    {
      name: "people.timeAndAttendance.timeSheet",
      route: "time-and-attendance/time-sheet",
      isFolhaCertaAllowed: MarcarPonto,
    },
    {
      name: "people.timeAndAttendance.workshift",
      route: "time-and-attendance/workshift",
      permissions: ["people_time_and_attendance_view_workshift"],
      isFolhaCertaAllowed: true,
    },
    {
      name: "people.timeAndAttendance.myVacations",
      route: "time-and-attendance/vacations",
      isFolhaCertaAllowed: ExibirFerias,
    },
    {
      name: "people.timeAndAttendance.approvals",
      route: "time-and-attendance/approvals",
      isFolhaCertaAllowed: isAdminOrGestor,
    },
    {
      name: "people.timeAndAttendance.activities",
      route: "time-and-attendance/activities",
      isFolhaCertaAllowed: isAdminOrGestor,
    },

    {
      name: "people.timeAndAttendance.events",
      route: "time-and-attendance/events",
      permissions: ["people_time_and_attendance_manage_events"],
      isFolhaCertaAllowed: true,
    },
    {
      name: "people.timeAndAttendance.manageWorkshifts",
      route: "time-and-attendance/manage-workshifts",
      permissions: ["people_time_and_attendance_manage_workshifts"],
      isFolhaCertaAllowed: true,
    },
    {
      name: "people.timeAndAttendance.vacations",
      route: "time-and-attendance/manage-vacations",
      permissions: ["people_time_and_attendance_manage_vacations"],
      isFolhaCertaAllowed: true,
    },
    {
      name: "people.timeAndAttendance.timeSheets",
      route: "time-and-attendance/time-sheets",
      permissions: ["people_time_and_attendance_manage_timesheets"],
      isFolhaCertaAllowed: true,
    },
    {
      name: "people.timeAndAttendance.integrations",
      route: "time-and-attendance/integrations",
      isFolhaCertaAllowed: isAdmin,
    },
    {
      name: "people.timeAndAttendance.exportData",
      route: "time-and-attendance/export-data",
      isFolhaCertaAllowed: isAdminOrGestor,
    },
    {
      name: "people.timeAndAttendance.importHistory",
      route: "time-and-attendance/import-history",
      isFolhaCertaAllowed: isAdmin,
    },
    {
      name: "people.timeAndAttendance.bankHours",
      route: "time-and-attendance/bank-hours",
      isFolhaCertaAllowed: isAdmin,
    },
    {
      name: "people.timeAndAttendance.preferences",
      route: "time-and-attendance/preferences",
      isFolhaCertaAllowed: isAdmin,
    },
  ];
};

const getAllowedSubMenus = (subMenus) =>
  subMenus
    .filter((subMenuItem) => Boolean(subMenuItem.isFolhaCertaAllowed))
    .map((subMenuItem) => {
      const permissions = subMenuItem?.permissions
        ? {
            permissions: subMenuItem.permissions,
          }
        : {};

      return {
        name: subMenuItem.name,
        route: subMenuItem.route,
        ...permissions,
      };
    });

export function getTimeAndAttendanceMenu(folhaCertaUser: UsuarioFolhaCerta) {
  const allSubMenus = getTimeAndAttendanceSubMenus(folhaCertaUser);

  const allowedSubMenus = getAllowedSubMenus(allSubMenus);

  return {
    name: "people.timeAndAttendance.timeAndAttendance",
    icon: "IconClock",
    isNew: true,
    subMenus: allowedSubMenus,
  };
}
