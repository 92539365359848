import { useEffect, useMemo, useContext, useState } from "react";
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2";
import { useAuth } from "@flash-hros/auth-helper";
import {
  usePermissions,
  useSelectedCompany,
  useLegacyPermissions,
  useContractModules,
} from "@flash-tecnologia/hros-web-utility";
import { Context } from "../../context";
import { getCompany, getPeopleContractModules } from "../../api";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import {
  Company,
  EExpenseContractType,
  MenuGroupsProps,
  MenuProps,
  MenusWithDividers,
  Notifications,
  SelectedProfile,
} from "./types";
import { useNotificationsMenu } from "../../hooks/useNotificationsMenu";
import { useExpensesContracts } from "../../hooks/useExpensesContracts";
import { useUnleashToggles } from "../../utils/useUnleashToggles";
import { mapEmployeeMenuFromV1 } from "./MenuGroups/Employee/mapEmployeeMenuFromV1";
import { mapAdminMenuFromV1 } from "./MenuGroups/Admin/mapAdminMenuFromV1";
import { getMenuGroups } from "../Menu/menuGroups";
import { useFolhaCertaUser } from "../../hooks/useFolhaCertaUser";
import { useCheckCorporateCardUser } from "../../hooks/useCheckCorporateCardUser";
import { checkUnleashToggle } from "../../utils/checkUnleashPermission";
import { useNavigate } from "react-router-dom";
import { useSelectedView } from "../../hooks/useSelectedProfile";

const MenuV2 = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);
  const accessToken = useAuth((state) => state.accessToken);
  const { companies, isAdmin } = usePermissions();
  const { selectedCompany } = useSelectedCompany();
  const unleashToggles = useUnleashToggles({ companyId: selectedCompany.id });
  const peopleContractModules = useContractModules();
  const { isCorporateCardUser } = useCheckCorporateCardUser();

  const {
    checkSectionPermission,
    isLoading,
    permissions: legacyPermissions,
  } = useLegacyPermissions();
  const [hasBenefits, setHasBenefits] = useState(
    checkSectionPermission("flash_benefits")
  );
  const companyPermissions = useMemo(() => {
    if (companies && selectedCompany?.id) {
      const selectedCompanyPermissions = companies.find(
        (company) => company.id === selectedCompany.id
      );
      return selectedCompanyPermissions;
    }
    return null;
  }, [companies, selectedCompany]);
  const hasSettings =
    companyPermissions?.permissions.includes("core_manage_company_settings") ||
    companyPermissions?.permissions.includes("*");

  const { expensesContractsData } = useExpensesContracts();

  const hasTimeAndAttendance = peopleContractModules?.modules?.includes?.(
    "time-and-attendance"
  );

  const { folhaCertaUser } = useFolhaCertaUser({
    enableRequest: hasTimeAndAttendance,
    selectedCompanyId: selectedCompany?.id,
  });

  let menuGroups = getMenuGroups(
    unleashToggles,
    companyPermissions?.permissions || [],
    isAdmin,
    hasSettings,
    isLoading,
    folhaCertaUser,
    selectedCompany?.id ?? "",
    peopleContractModules || null,
    expensesContractsData,
    isCorporateCardUser
  );
  const notifications: Notifications = useNotificationsMenu(menuGroups);
  const isMobile = useMediaQuery("(max-width: 768px)");

  if (
    !hasBenefits ||
    checkUnleashToggle("FLASH_OS_BLOCKED_TO_BENEFITS_SECTION", unleashToggles)
  )
    menuGroups = menuGroups.filter((group) => group.id !== "flash_benefits");

  if (checkUnleashToggle("FLASH_OS_BLOCKED_TO_MY_FLASH", unleashToggles)) {
    const index = menuGroups[0].menus.findIndex(
      (menuItem) => menuItem.name === "myFlash.title"
    );
    menuGroups[0].menus.splice(index, 1);
  }

  const expenseAdminAnalyticsPermissions = [
    "*",
    "expense_view_reports_approval",
    "expense_manage_corporate_cards",
    "expense_manage_corporate_card_balances",
    "expense_vendor_view",
    "expense_view_financial_reports",
    "expense_payables_account_view",
    "expense_view_approvals_advances",
    "expense_view_customizations",
  ];
  if (
    companyPermissions?.permissions?.some((permission) =>
      expenseAdminAnalyticsPermissions?.includes(permission)
    ) &&
    (expensesContractsData?.features?.includes("BASIC_ANALYTICS") ||
      expensesContractsData?.contracts?.some(
        (contract) =>
          contract === EExpenseContractType.FLASH_APP_CONTRACT_CORPORATE_CARD
      ))
  ) {
    menuGroups
      .find((menu) => menu.id === "flash_expenses")
      ?.menus?.push({
        name: "expenses.admin.analytics",
        icon: "IconBuildingStore",
      });
  }

  const peopleAdminOrgchartPermission = ["*", "people_orgchart_update"];
  const hasPeopleOrgchart = menuGroups
    .find((menu) => menu.id === "flash_people")
    ?.menus?.find((submenu) => submenu.name === "people.orgchart");

  if (
    hasPeopleOrgchart &&
    companyPermissions?.permissions?.some((permission) =>
      peopleAdminOrgchartPermission.includes(permission)
    )
  ) {
    menuGroups
      .find((menu) => menu.id === "flash_people")
      .menus.push({
        name: "people.admin.orgchart",
        icon: "IconBuildingStore",
      });
  }

  const hasPeoplePermissionByFolhaCerta =
    folhaCertaUser?.IsAdmin || folhaCertaUser?.IsGestor;

  if (hasPeoplePermissionByFolhaCerta) {
    const timeAndAttendanceMenu = {
      ...menuGroups
        ?.find((menu) => menu.id === "flash_people")
        ?.menus?.find(
          (menu) => menu.name === "people.timeAndAttendance.timeAndAttendance"
        ),
    };

    menuGroups
      .find((menu) => menu.id === "flash_people")
      .menus.push(timeAndAttendanceMenu);
  }

  const employeeMenuGroups = mapEmployeeMenuFromV1(menuGroups);

  const adminMenuGroups = mapAdminMenuFromV1({
    menuV1Groups: menuGroups,
    hasSettings,
  });

  const hasAdminProfile = adminMenuGroups.menus.length > 1;

  const { selectedProfile, handleSelectedView } = useSelectedView(
    hasAdminProfile,
    adminMenuGroups,
    employeeMenuGroups
  );

  useEffect(() => {
    setHasBenefits(checkSectionPermission("flash_benefits"));
  }, [legacyPermissions.benefits]);

  useEffect(() => {
    (async () => {
      await getCompany({ dispatch });
    })();
  }, [accessToken]);

  useEffect(() => {
    const checkPeopleContractModules = async ({
      detail,
    }: CustomEvent<Company>) => {
      const { contractStatus } = await getPeopleContractModules(detail?.id);
      useAuth.setState({ contractStatus });
    };

    window.addEventListener(
      "header_company_selected",
      checkPeopleContractModules
    );

    () =>
      window.removeEventListener(
        "header_company_selected",
        checkPeopleContractModules
      );
  }, []);

  const handleSelectProfile = (profile: SelectedProfile) => {
    if (profile !== selectedProfile) {
      handleSelectedView(profile);

      if (profile === "admin") {
        navigate("/home/admin");
        return;
      }

      navigate("/home/employee");
    }
  };

  const visibleMenus =
    selectedProfile === "admin" ? adminMenuGroups : employeeMenuGroups;
  const visibleMenusWithDividers: MenusWithDividers = [];

  visibleMenus.menus.forEach(
    (item: MenuGroupsProps | MenuProps, index: number) => {
      const previousItemIsGroup =
        index === 0 ? false : "menus" in visibleMenus.menus[index - 1];
      const currentItemIsGroup = !!("menus" in item);
      if (
        (currentItemIsGroup && !previousItemIsGroup) ||
        item.name === "employee.profileSettings.title"
      ) {
        visibleMenusWithDividers.push("topDivider");
      }
      visibleMenusWithDividers.push(item);
      if (currentItemIsGroup && visibleMenus.menus[index + 1]) {
        if ("menus" in visibleMenus.menus[index + 1]) {
          visibleMenusWithDividers.push("centerDivider");
        } else {
          visibleMenusWithDividers.push("bottomDivider");
        }
      }
    }
  );

  if (isMobile) {
    return (
      <Mobile
        menuGroups={{
          menus: visibleMenusWithDividers,
          newProducts: visibleMenus.newProducts,
        }}
        hasSettings={hasSettings}
        notifications={notifications}
        selectedProfile={selectedProfile}
        onSelectProfile={handleSelectProfile}
        hasAdminProfile={hasAdminProfile}
      />
    );
  }

  return (
    <Desktop
      menuGroups={{
        menus: visibleMenusWithDividers,
        newProducts: visibleMenus.newProducts,
      }}
      notifications={notifications}
      selectedProfile={selectedProfile}
      onSelectProfile={handleSelectProfile}
      hasAdminProfile={hasAdminProfile}
    />
  );
};

export default MenuV2;
