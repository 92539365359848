import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { BrowserRouter as Router } from "react-router-dom";
import { FlagsProvider } from "@flash-tecnologia/feature-flags";

import { ConfigurationContext } from "./context";
import { useSession } from "./hooks/use-session";

import "../src/i18n";
import { MenuV2 } from "./components";

export default function Root() {
  const { userId, economicGroupId, companyId } = useSession();
  return (
    <ThemeProvider>
      <FlagsProvider
        appName="hros-web-menu"
        url={process.env.UNLEASH_PROXY_URL}
        token={process.env.UNLEASH_PROXY_KEY}
        refreshIntervalSeconds={1800}
        initialContext={{
          userId,
          properties: {
            economicGroupId,
            companyId,
            employeeId: userId,
          },
        }}
      >
        <Router>
          <ConfigurationContext>
            <MenuV2 />
          </ConfigurationContext>
        </Router>
      </FlagsProvider>
    </ThemeProvider>
  );
}
