import { Dispatch } from "react";
import { Axios, getFromLS, setInLS } from "@flash-tecnologia/hros-web-utility";

import { EActions, IActions } from "../context/actions";
import { ErrorBoundary } from "../utils/ErrorBoundary";
import { Notifications } from "../components/Menu/types";
import { TServiceName } from "@flash-tecnologia/hros-web-utility/dist/services";

interface getCompanyProps {
  dispatch: Dispatch<IActions>;
}

export const getCompany = async ({ dispatch }: getCompanyProps) => {
  try {
    const accessToken = getFromLS("hrosAccessToken");

    if (!accessToken) return;

    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: "getCompany",
    });

    // if (data?.value?.logo?.path) {
    //   try {
    //     const s3Presigned = await getS3Presigned({
    //       filename: data.value.logo.path,
    //       module: "employee-pii",
    //     });

    //     if (s3Presigned?.url) logo = s3Presigned.url;
    //   } catch (e) {}
    // }

    let companyData = { logo: null };

    dispatch({
      type: EActions.GET_COMPANY,
      payload: {
        ...companyData,
      },
    });

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: "getCompany",
    });

    return companyData;
  } catch (error) {
    ErrorBoundary.captureException(error);
    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: "getCompany",
    });

    throw error;
  }
};

export async function getExpensesContracts(companyId: string) {
  try {
    const { data } = await Axios({
      service: "expenseManagement",
      method: "get",
      url: `/api/v1/users/me/contracts`,
      axiosOptions: {
        timeout: 30000, // 30 seconds
        headers: {
          "Company-Id": companyId,
        },
      },
    });

    return { ...data, companyId };
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw error;
  }
}

export async function getNotificationsMenus(
  companyId: string,
  dataServices: {
    route: string;
    serviceName: TServiceName;
  }[]
) {
  let notifications: Notifications = {};

  if (!dataServices.length) {
    return null;
  }

  for await (const dataService of dataServices) {
    let axiosOptions = {
      timeout: 30000, // 30 seconds
    };

    if (dataService?.serviceName === "expenseManagement")
      axiosOptions["headers"] = { "Company-Id": companyId };
    else if (dataService?.serviceName === "corporateCardBff")
      axiosOptions["headers"] = { "company-id": companyId };

    if (dataService.route && dataService.serviceName)
      try {
        const { data } = await Axios({
          service: dataService.serviceName,
          method: "get",
          url: dataService.route,
          axiosOptions,
        });
        let result: Notifications;
        if (dataService.serviceName === "corporateCardBff") {
          result = data?.result?.data?.json ?? {};
        } else {
          result = data;
        }
        notifications = { ...notifications, ...result };
      } catch (error) {
        console.error("Notifications menu error => ", error);
      }
  }

  return notifications;
}

export async function getPeopleContractModules(companyId: string) {
  try {
    const {
      data: { value },
    } = await Axios({
      service: "billingManager",
      method: "get",
      url: `/contract-setup/status/${companyId}`,
    });

    const contractSetup = {
      contractId: value?.contractId,
      modules: value?.modules,
    };

    dispatchEvent(
      new CustomEvent("people_contract_modules_updated", {
        detail: contractSetup,
      })
    );
    setInLS({
      key: "people_contract_modules",
      value: contractSetup,
    });

    return value;
  } catch (error) {
    dispatchEvent(
      new CustomEvent("people_contract_modules_updated", {
        detail: { contractId: undefined, modules: [] },
      })
    );
    setInLS({
      key: "people_contract_modules",
      value: { contractId: undefined, modules: [] },
    });

    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}

export interface UsuarioFolhaCerta {
  Id: number;
  EmpresaId: number;
  Nome: string;
  Email: string;
  Cargo: string;
  NomeDepartamento: string | null;
  IsAdmin: boolean;
  IsGestor: boolean;
  Configuracao: {
    MarcarPonto: boolean;
    ExibirMarcarPontoApp: boolean;
    ExibirMarcarPontoWeb: boolean;
    ExibirFerias: boolean;
    PermitirGestorIncluirMarcacao: boolean;
    PermitirGestorJustificarMarcacao: boolean;
    PermitirGestorDesconsiderarMarcacao: boolean;
    PermitirFuncionarioCorrigirPonto: number;
    UsuariosGestores: {
      Id: number;
      Nome: string;
      Foto: string;
      Cargo: string;
    }[];
    RegraInterjornada: {
      Habilitar: boolean;
      HabilitarKiosk: boolean;
    };
    RegraIntrajornada: {
      Habilitar: boolean;
      HabilitarKiosk: boolean;
    };
    PermitirDelegarGestao: boolean;
  };
}

export async function getFolhaCertaPermissions({
  enableRequest,
  selectedCompanyId,
}: {
  enableRequest;
  selectedCompanyId;
}): Promise<UsuarioFolhaCerta> {
  if (!enableRequest) return null;

  try {
    const { data } = await Axios({
      service: "folhaCertaAuth",
      method: "get",
      url: `/v2/configuration/user`,
      axiosOptions: {
        timeout: 30000, // 30 seconds
        queryParams: {
          "api-version": "1.0",
        },
        headers: {
          "X-Flash-CompanyId": selectedCompanyId,
        },
      },
    });

    return data.Data;
  } catch (error) {
    ErrorBoundary.captureException(error);
    return null;
  }
}
